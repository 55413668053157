import { create } from "zustand";

const windowGlobal = typeof window !== "undefined" && window;

const DEFAULT_GRID_CELL_SIZE = 350;
const DEFAULT_GRID_MARGINS = { lg: 64, md: 30, sm: 15 };
const DEFAULT_GRID_MARGIN_DESKTOP = 64;
// const DEFAULT_GRID_MARGIN_MOBILE = 15;

export const useUiStore = create((set) => ({
  // Properties:
  navigationOpen: false,
  loaderFinished: false,
  location: null,
  headerShrunk: false,
  headerSize: null,
  isMobileSearchOverlayOpened: false,

  // Methods
  setLoaderFinished: () => set({ loaderFinished: true }),
  setLocation: (location) => set({ location }),
  setNavigationOpenState: (navigationOpen) => set({ navigationOpen }),
  openNav: () => set({ navigationOpen: true }),
  closeNav: () => set({ navigationOpen: false }),
  setHeaderShrunkState: (headerShrunk) => set({ headerShrunk }),
  setHeaderSize: (headerSize) => set({ headerSize }),
  setIsMobileSearchOverlayOpened: (isMobileSearchOverlayOpened) =>
    set({ isMobileSearchOverlayOpened }),
}));

export const useLangStore = create((set) => ({
  // Properties:
  currentLang: (
    (windowGlobal &&
      windowGlobal?.navigator?.language.substr(0, 2)?.toLowerCase()) ||
    ""
  ).includes("zh")
    ? "zh"
    : "en",

  // Methods:
  setCurrentLang: (currentLang) => set({ currentLang }),
}));

export const useDeviceStore = create((set, get) => ({
  // Properties:
  windowWidth: null,
  windowHeight: null,
  isMobile: null,
  gridMargin: DEFAULT_GRID_MARGINS.lg,
  gridCellSize: DEFAULT_GRID_CELL_SIZE,
  gridCenterCols: 0,

  // Methods:
  setWindowWidth: (windowWidth) => {
    const { gridCellSize } = get();
    const centerCols = Math.floor(
      (windowWidth - DEFAULT_GRID_MARGIN_DESKTOP * 2) / gridCellSize
    );
    const gridCenterCols = centerCols >= 1 ? centerCols : 1;
    const isMobile = centerCols <= 1;
    const gridMargin =
      // eslint-disable-next-line no-nested-ternary
      centerCols <= 1
        ? DEFAULT_GRID_MARGINS.sm
        : centerCols === 2
        ? DEFAULT_GRID_MARGINS.md
        : DEFAULT_GRID_MARGINS.lg;

    set({ windowWidth, gridCenterCols, isMobile, gridMargin });
  },
  setWindowHeight: (windowHeight) => set({ windowHeight }),
  setIsMobile: (isMobile) => set({ isMobile }),
  setGridMarginSize: ({ margin }) => set({ margin }),
  setGridCellSize: ({ cellSize }) => set({ cellSize }),
  setGridCenterCols: ({ centerCols }) => set({ centerCols }),
}));

export const useLightboxStore = create((set) => ({
  // Properties:
  isOpen: false,
  isFullscreened: false,

  // Methods:
  setIsFullscreened: (isFullscreened) => set({ isFullscreened }),
  setIsOpen: (isOpen) => set({ isOpen }),
}));
