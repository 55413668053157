export default {
  desktop: {
    h0: {
      // styleName: WMA/Desktop/H0;
      fontFamily: {
        en: "TWK Everett",
        zh: "Noto Sans, TWK Everett",
      },
      fontSize: 96,
      fontWeight: 400,
      lineHeight: 102,
      letterSpacing: "-0.04em",
    },
    h1: {
      // styleName: WMA/Desktop/H1;
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 64,
      fontWeight: 700,
      lineHeight: 72,
      letterSpacing: "-0.04em",
    },
    h2: {
      // styleName: WMA/Desktop/H2,
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 32,
      fontWeight: 700,
      lineHeight: 44,
      letterSpacing: "-0.03em",
    },
    h3: {
      // styleName: WMA/Desktop/H3,
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 20,
      fontWeight: 700,
      lineHeight: 28,
      letterSpacing: "0em",
    },
    h4: {
      // styleName: WMA/Desktop/H3,
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 16,
      fontWeight: 700,
      lineHeight: 22,
      letterSpacing: "0em",
    },
    menuItem: {
      // styleName: WMA/Desktop/MenuItem;
      fontFamily: {
        en: "TWK Everett",
        zh: "Noto Sans, TWK Everett",
      },
      fontSize: 46,
      fontWeight: 400,
      lineHeight: 50,
      letterSpacing: "-0.04em",
      case: "uppercase",
    },
    subMenuItem: {
      // styleName: WMA/Desktop/MenuItem;
      fontFamily: {
        en: "TWK Everett",
        zh: "Noto Sans, TWK Everett",
      },
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 24,
    },
    p: {
      // styleName: WMA/Desktop/Body,
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 24,
      letterSpacing: "0em",
    },
    link: {
      // styleName: WMA/Desktop/Link,
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 24,
      letterSpacing: "0em",
    },
    label: {
      // styleName: WMA/Desktop/Label,
      fontFamily: {
        en: "TWK Everett Mono",
        zh: "Noto Sans, TWK Everett",
      },
      fontSize: 12,
      fontWeight: 300,
      lineHeight: 14,
      letterSpacing: "0em",
    },
    labelLarge: {
      // styleName: WMA/Desktop/Label /large,
      fontSize: 14,
      lineHeight: 24,
    },
    footerText: {
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 12,
      fontWeight: "400",
      lineHeight: 19,
      letterSpacing: "0em",
    },
    searchInput: {
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 20,
      fontWeight: "400",
      lineHeight: "26px",
      letterSpacing: "0em",
    },
    searchTab: {
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 14,
      fontWeight: "400",
      lineHeight: "17px",
      letterSpacing: "0em",
    },
    searchEmpty: {
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 20,
      fontWeight: "400",
      lineHeight: "27px",
      letterSpacing: "0em",
    },
    searchLoadMore: {
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 18,
      fontWeight: "400",
      lineHeight: "22px",
      letterSpacing: "0em",
    },
    searchSuggestionLabel: {
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 14,
      fontWeight: "400",
      lineHeight: "18px",
      letterSpacing: "0em",
    },
    searchSuggestionItem: {
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 20,
      fontWeight: "400",
      lineHeight: "27px",
      letterSpacing: "0em",
    },
    creativeFeatureHighlightItemTitle: {
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 24,
      fontWeight: "700",
      lineHeight: "32px",
      letterSpacing: "0em",
    },
    creativeFeatureHighlightItemLink: {
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 14,
      fontWeight: "400",
      lineHeight: "24px",
      letterSpacing: "0em",
    },
    creativeFeatureHighlightItemDescription: {
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 14,
      fontWeight: "400",
      lineHeight: "17px",
      letterSpacing: "0em",
    },
  },
  mobile: {
    h0: {
      // styleName: WMA/Mobile/H0
      fontFamily: {
        en: "TWK Everett",
        zh: "Noto Sans, TWK Everett",
      },
      fontSize: 64,
      fontWeight: 400,
      lineHeight: 102,
      letterSpacing: "-0.04em",
    },
    h1: {
      // styleName: WMA/Mobile/H1
      fontFamily: {
        en: "TWK Everett",
        zh: "Noto Sans, TWK Everett",
      },
      fontSize: 36,
      fontWeight: 700,
      lineHeight: 40,
      letterSpacing: "-0.04em",
    },
    h2: {
      // styleName: WMA/Mobile/H2
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 26,
      fontWeight: 700,
      lineHeight: 40,
      letterSpacing: "-0.04em",
    },
    h3: {
      // styleName: WMA/Mobile/H3,
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 18,
      fontWeight: 700,
      lineHeight: 24,
      letterSpacing: "-0.03em",
    },
    h4: {
      // styleName: WMA/Mobile/H3,
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 16,
      fontWeight: 700,
      lineHeight: 20,
      letterSpacing: "-0.03em",
    },
    p: {
      // styleName: WMA/Mobile/P,
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 20,
      letterSpacing: "0em",
    },
    menuItem: {
      // styleName: WMA/Mobile/MenuItem;
      fontFamily: {
        en: "TWK Everett",
        zh: "Noto Sans, TWK Everett",
      },
      fontSize: 36,
      fontWeight: 400,
      lineHeight: 46,
      letterSpacing: "-0.04em",
      case: "uppercase",
    },
    subMenuItem: {
      // styleName: WMA/Mobile/MenuItem;
      fontFamily: {
        en: "TWK Everett",
        zh: "Noto Sans, TWK Everett",
      },
      fontSize: 20,
      fontWeight: 400,
      lineHeight: 26,
    },
    link: {
      // styleName: WMA/Mobile/Link,
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 15,
      fontWeight: 400,
      lineHeight: 20,
      letterSpacing: "0em",
    },
    label: {
      // styleName: WMA/Mobile/Label,
      fontFamily: {
        en: "TWK Everett Mono",
        zh: "Noto Sans, TWK Everett",
      },
      fontSize: 15,
      fontWeight: 300,
      lineHeight: 15,
      letterSpacing: "0em",
    },
    labelLarge: {
      fontSize: 14,
      lineHeight: 24,
    },
    footerText: {
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 12,
      fontWeight: "400",
      lineHeight: 18,
      letterSpacing: "0em",
    },
    searchInput: {
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 20,
      fontWeight: "400",
      lineHeight: "26px",
      letterSpacing: "0em",
    },
    searchTab: {
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 14,
      fontWeight: "400",
      lineHeight: "17px",
      letterSpacing: "0em",
    },
    searchEmpty: {
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 20,
      fontWeight: "400",
      lineHeight: "27px",
      letterSpacing: "0em",
    },
    searchLoadMore: {
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 18,
      fontWeight: "400",
      lineHeight: "22px",
      letterSpacing: "0em",
    },
    searchSuggestionLabel: {
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 14,
      fontWeight: "400",
      lineHeight: "18px",
      letterSpacing: "0em",
    },
    searchSuggestionItem: {
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 20,
      fontWeight: "400",
      lineHeight: "27px",
      letterSpacing: "0em",
    },
    creativeFeatureHighlightItemTitle: {
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 24,
      fontWeight: "700",
      lineHeight: "32px",
      letterSpacing: "0em",
    },
    creativeFeatureHighlightItemLink: {
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 14,
      fontWeight: "400",
      lineHeight: "24px",
      letterSpacing: "0em",
    },
    creativeFeatureHighlightItemDescription: {
      fontFamily: { en: "TWK Everett", zh: "Noto Sans, TWK Everett" },
      fontSize: 14,
      fontWeight: "400",
      lineHeight: "17px",
      letterSpacing: "0em",
    },
  },
};
